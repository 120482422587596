import {defineStore} from "pinia"
import type {UserData} from '~/types/UserData'
import type {GeoData} from '~/types/GeoData'
import type {FeaturesData} from '~/types/FeaturesData'
import {hasDirectoryUnseenCapture} from "~/composables/useCaptureIsSeen"
import {useDomainDataStore} from '~/stores/domain'
import type {PageData} from '~/types/PageData'

export const useUserStore = defineStore('user', () => {

    const defaultGeoData: GeoData = {
        country: 'FR',
        city: 'Bordeaux',
        isEurope: true,
        currency: 'EUR',
    }

    const defaultFeatures: FeaturesData = {
        // Only defined to please TypeScript, will be overridden on next /user API call
        withNewAccount: false,
        withLogin: true,
        withPasswordForgotten: true,
        withChangeInstitution: false,
        withContact: true,
        withNewsletter: true,
        disableOutgoingLinks: false,
        withLogout: false,
        hideFavoriteTool: false,
        hideShareTool: false,
        hideVideoCaptureTool: false,
        hideFlipTool: false,
        withProfile: true,
    }

    const dataTimestamp = useGlobalCookie<string|undefined>('user-store.data-timestamp')
    const isAuthenticatedCookie = useGlobalCookie<boolean|undefined>('user-store._ia')

    const isAuthenticated = computed(() => {
        return isAuthenticatedCookie.value
    })

    const userData = ref<UserData>({
        features: defaultFeatures,
        geo: defaultGeoData
    })

    const geoData = computed<GeoData>(() => {
        return userData.value.institution ?
            userData.value.institution :
            userData.value.geo ?? defaultGeoData
    })

    function setData(data: UserData) {
        isAuthenticatedCookie.value = true
        dataTimestamp.value = Date.now().toString()
        userData.value = data
    }

    const maxDataAge = 5 * 60 * 1000 // 5 minutes

    // Not a `ref` because is based on time
    function mustRefreshData() {
        // @ts-ignore
        return Date.now() - dataTimestamp.value > maxDataAge
    }

    function resetData(data: UserData|null) {
        isAuthenticatedCookie.value = undefined
        dataTimestamp.value = undefined
        userData.value = data || {features: defaultFeatures, geo: defaultGeoData}
    }

    const isLibrary = computed(() => userData.value.institution?.type === 'library')
    const isTeacher = computed(() => userData.value.roles?.includes('ROLE_TEACHER'))
    const hasMultipleCatalogs = computed(() => userData.value.catalogs ? userData.value.catalogs.length > 1 : false)
    const isHigher = computed(() => userData.value.institution?.isHigher === true)

    const stringRepresentation = computed(() => {
        const user = userData.value

        if (user.institution?.name) {
            return user.institution.name
        }

        return user.id ?`User #${user.id}` : null
    })

    const hasUnseenCaptures = computed(() => {
        return userData.value.directories?.some(directory => {
            return hasDirectoryUnseenCapture(directory)
        })
    })

    function ownsDirectoryWithCode(code: string) {
        return userData.value.directories?.some(directory => {
            return directory.code === code
        })
    }

    const pageGroups = computed(() => {
        const domainPageGroups = useDomainDataStore().domainData.pageGroups
        const pageOverrides = userData.value.pageOverrides
        if (!pageOverrides) {
            return domainPageGroups
        }

        return domainPageGroups.map((pageGroup) => {
            return pageGroup.map((page) => {
                return getPublicPageWithEnglishSlug(page.englishSlug) ?? page
            })
        })
    })

    const fullName = computed(() => {
        const parts = []
        if (userData.value.firstname) {
            parts.push(userData.value.firstname)
        }

        if (userData.value.lastname) {
            parts.push(userData.value.lastname)
        }

        if (parts.length === 0) {
            return null
        }

        return parts.join(' ')
    })

    function getPublicPageWithEnglishSlug(slug: string): PageData|null {
        const pageOverrides = userData.value.pageOverrides
        if (!pageOverrides) {
            return useDomainDataStore().getPublicPageWithEnglishSlug(slug)
        }

        if (pageOverrides[slug]) {
            return useDomainDataStore().getPublicPageWithEnglishSlug(pageOverrides[slug])
        }

        return null
    }

    return {
        isAuthenticated,

        mustRefreshData,
        setData,
        resetData,

        isLibrary,
        isTeacher,
        hasMultipleCatalogs,
        isHigher,
        hasUnseenCaptures,
        ownsDirectoryWithCode,

        userData,
        userId: computed(() => userData.value.id),
        geoData,
        pageGroups,
        getPublicPageWithEnglishSlug,

        stringRepresentation,
        fullName,
    }
})
