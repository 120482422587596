import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_KZ0f4ARq4r from "/app/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import plugin_BqLkNUef68 from "/app/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import Packery_client_4NLb2WGpyj from "/app/plugins/Packery.client.ts";
import auth_vT9JWWT9pN from "/app/plugins/auth.ts";
import directive_I6YZ1QHKAO from "/app/plugins/directive.ts";
import floating_vue_BiOD74u9sH from "/app/plugins/floating-vue.ts";
import imagesloaded_client_AftisBaDqb from "/app/plugins/imagesloaded.client.ts";
import luxon_locale_GbnGfc5PNR from "/app/plugins/luxon-locale.ts";
import mitt_S0QU5gJPTl from "/app/plugins/mitt.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import toastify_client_zzPWHgjcj4 from "/app/plugins/toastify.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_KZ0f4ARq4r,
  plugin_BqLkNUef68,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  Packery_client_4NLb2WGpyj,
  auth_vT9JWWT9pN,
  directive_I6YZ1QHKAO,
  floating_vue_BiOD74u9sH,
  imagesloaded_client_AftisBaDqb,
  luxon_locale_GbnGfc5PNR,
  mitt_S0QU5gJPTl,
  sentry_client_shVUlIjFLk,
  toastify_client_zzPWHgjcj4
]