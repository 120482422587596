import {useUserStore} from "~/stores/user"
import * as querystring from 'querystring'

const { getSession } = useSession()

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore()

  const isLogout = to.path === '/logout'

  if (import.meta.client && isLogout) {
    return useSession().signOut()
  }

  // If we're on the server side, and the cookies tell us we're authenticated,
  // then we can safely initialize the user store, so it is immediately available
  if (import.meta.server && userStore.isAuthenticated) {
    // Note: We ignore redirect data here
    try {
      await getSession()
    } catch (e) {
      // User was actually not authenticated anymore
    }
  }

  const requestEvent = useRequestEvent()

  if (import.meta.server && requestEvent?.method === 'POST') {
    let body = '', tmp = ''
    while (tmp = requestEvent.node.req.read()) {
      body += tmp
    }

    useState('requestPostData', () => querystring.parse(body))
  } else {
    useState('requestPostData', () => null)
  }

  // If we're on the client side and the user is supposed to be authenticated
  // we regularly refresh the session data. If the session is not valid anymore,
  // we reload the page, which will redirect to the login page.
  if (import.meta.client && userStore.isAuthenticated && userStore.mustRefreshData()) {
    try {
      await getSession()
    } catch (e) {
      window.location.reload()
    }
  }
})
