import type {CookieOptions, CookieRef} from '#app/composables/cookie'
import {useDomainDataStore} from '~/stores/domain'

export const useGlobalCookie = <T = string | null | undefined>(name: string, _opts?: CookieOptions<T> & { readonly?: false }): CookieRef<T> => {
  if (!_opts) {
    _opts = {}
  }

  if (!_opts.domain) {
    let bareDomain = useRequestURL().host
      // Remove the subdomain from the URL
      .replace(new RegExp('^' + useDomainDataStore().domainData.domain), '')
      // Remove the port from the URL
      .replace(/:\d+$/, '')

    // Proxy case (subdomains are not necessarily `www` or `junior`,
    // so it might look like `-edumedia-com.proxy-domain.tld` after the replacement)
    if (bareDomain[0] !== '.') {
      // So we remove the first part of the domain, up to the first dot
      const parts = bareDomain.split('.')
      parts.shift()
      bareDomain = '.' + parts.join('.')
    }

    _opts.domain = bareDomain
  }

  return useCookie(name, _opts)
}
