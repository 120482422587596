import type {Price} from '~/types/Price'
import {storeToRefs} from 'pinia'
import {useDomainDataStore} from '~/stores/domain'
import {useUserStore} from '~/stores/user'
import type {UseFetchOptions} from '#app'
import type {CapturePageData} from '~/types/media/CapturePageData'

export interface ApiFetchOptions extends UseFetchOptions<any> {
  deep?: boolean,
  noCache?: boolean,
}

function useCustomFetch<DataT>(url: string, options: ApiFetchOptions = {}): ReturnType<typeof useFetch<DataT>> {

  options = ensureDomainOption(options)

  let headers = useRequestHeaders([
    'cookie',
    'x-forwarded-for',
  ])

  let fetchHeaders: Record<string, string> = {}

  if (headers.cookie) {
    fetchHeaders.cookie = headers.cookie
  }

  // If client IP is forwarded, pass it, but with a custom name (used in GeoService)
  if (headers['x-forwarded-for']) {
    fetchHeaders['x-edumedia-forwarded-for'] = headers['x-forwarded-for']
  }

  if (options.deep !== true) {
    options.deep = false
  }

  if (String(options.method).toLowerCase() === 'post') {
    options.key = `useCustomFetch:post:${new Date().getTime().toString()}`
  } else if (options.noCache) {
    options.key = `useCustomFetch:noCache:${new Date().getTime().toString()}`
  }

  return useFetch(url, {credentials: 'include', headers: fetchHeaders, ...options})
}

export const REDIRECT_PENDING_ERROR = 'redirect-pending'

const preferredApiPrefix = computed(() => {
  const {apiPrefix, serverToServerApiPrefix} = storeToRefs(useDomainDataStore())
  return import.meta.server ? serverToServerApiPrefix.value : apiPrefix.value
})

function ensureDomainOption(options: ApiFetchOptions) {
  if (!import.meta.server) {
    return options
  }

  if (!options.params) {
    options.params = {}
  }

  options.params = {
    ...options.params,
    'server-to-server-domain': useDomainDataStore().domainData.domain,
  }

  return options
}

export const useApiFetch = <DataT>(url: string, options: ApiFetchOptions = {}) => {

  if (routeIsAwaitingLocalizedRedirection()) {
    return {
      data: ref(null),
      error: ref(REDIRECT_PENDING_ERROR),
    }
  }

  const {$i18n} = useNuxtApp()

  if (!options.key) {
    options.key = `useApiFetch:${$i18n.locale.value}:${url}`
  }

  return useCustomFetch<DataT>(`${preferredApiPrefix.value}/${$i18n.locale.value}${url}`, options)
}

export const useBaseApiFetch = <DataT>(url: string, options: ApiFetchOptions = {}) => {

  if (routeIsAwaitingLocalizedRedirection()) {
    console.warn('useBaseApiFetch: route is awaiting localized redirection')
    return {
      data: ref(null),
      error: ref(REDIRECT_PENDING_ERROR),
    }
  }

  if (!options.key) {
    options.key = `useBaseApiFetch:${url}`
  }

  return useCustomFetch<DataT>(`${preferredApiPrefix.value}${url}`, options)
}

export const useApiRoute = (url: string) => {
  const {apiPrefix} = storeToRefs(useDomainDataStore())
  const {$i18n} = useNuxtApp()

  return `${apiPrefix.value}/${$i18n.locale.value}${url}`
}

export const usePriceCheckoutRoute = (p: Price) => {
  const userStore = useUserStore()
  return useApiRoute(`/purchase/checkout/${userStore.geoData.currency}-${p.duration}-${p.catalog}-${p.schoolSize}`)
}

export const useCaptureFetch = (code: string) => {
  return useApiFetch<CapturePageData>(`/capture/${code}`)
}

export const postCaptureFlag = (code: string, reason: string) => {
  const body = new FormData()
  body.append('reason', reason)

  return useApiFetch(`/capture/${code}/flag`, {method: 'post', body})
}